button:focus {
  outline: none;
}
button:hover {
  background-color: white;
}
.my-component {
  background: #fff;
}

.fullscreen-enabled .my-component {
  background: #000;
}
.fullscreen-enabled > div {
  height: 100vh;
  overflow-y: scroll;
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-23 {
  font-size: 20px;
}
.material-icons.md-32 {
  font-size: 32px;
}
.material-icons.md-36 {
  font-size: 36px;
}

.material:hover {
  background-color: #e0e0e0;
  border-radius: 50%;
  color: #000;
}
